import { ComponentActions } from "@dk/client/bootstrap"
import { forTarget, forTargets } from "@dk/client/content"
import { mount } from "@dk/client/mount"
import { PersistentRecord } from "@dk/client/store"
import { fromEvent, BehaviorSubject } from "rxjs"

type Props = {
  myViews$: PersistentRecord<string[]>,
}

const VIDEO_TAG_CURRENT = 'video-card'
const VIDEO_TAG_PREVIOUS = 'video-card-previous-winner'

export default async function Views (root: RootElement, props: Props): Promise<ComponentActions> {
  const currentView$ = new BehaviorSubject<string>('view-all')

  const updateViews = (views: string[]) => {
    if (!views || views.length === 0) {
      return
    }

    const thumbnails = document.querySelectorAll(
      views.map((view) => `[dk-target="video-thumbnail"][data-video-id="${view}"]`).join(','),
    ) as NodeListOf<HTMLDivElement>

    thumbnails.forEach((thumbnail) => {
      const container = thumbnail.closest('[dk-value="video-card"]') as HTMLDivElement

      const targetElement = forTarget<HTMLDivElement>(container, 'viewed-icon')

      if (targetElement) {
        targetElement.style.display = 'flex'
      }
    })
  }

  function registerVideoPlayerClickListeners() {
    const thumbnails = forTargets<HTMLElement>(root, 'video-thumbnail')
    thumbnails.forEach((thumbnail) => {
      fromEvent(thumbnail!, 'click').subscribe((e) => {
        const target = e.currentTarget as HTMLElement
        const videoID = target.dataset.videoId || ''
        const myViews = props.myViews$.getValue()!
        props.myViews$.next([...myViews, videoID])
      })
    })
  }

  function handleFilters() {
    const filters = forTargets<HTMLDivElement>(root, 'filter-button')

    filters.forEach((filter) => {
      fromEvent(filter!, 'click').subscribe((e) => {
        const target = e.currentTarget as HTMLButtonElement

        filters.forEach((filter) => filter?.classList.remove('active'))
        target.classList.add('active')

        const action = target.dataset.action || ''
        currentView$.next(action)
      })
    })
  }

  const handleFilterChange = (action: string) => {
    if (action === 'view-all') {
      // show all the videos
      forTargets<HTMLDivElement>(root, VIDEO_TAG_CURRENT).forEach((card) => {
        card?.classList.remove('hidden')
      })

      // hide the videos that won last year
      forTargets<HTMLDivElement>(root, VIDEO_TAG_PREVIOUS).forEach((card) => {
        card?.classList.add('hidden')
      })
    } else if (action === 'show-winners') {
      // hide all the videos
      forTargets<HTMLDivElement>(root, VIDEO_TAG_CURRENT).forEach((card) => {
        card?.classList.add('hidden')
      })

      // show the videos that won last year
      forTargets<HTMLDivElement>(root, VIDEO_TAG_PREVIOUS).forEach((card) => {
        card?.classList.remove('hidden')
      })
    } else if (action === 'hide-viewed') {
      // hide all the videos
      forTargets<HTMLDivElement>(root, VIDEO_TAG_CURRENT).forEach((card) => {
        card?.classList.add('hidden')
      })

      // hide all last year's winners
      forTargets<HTMLDivElement>(root, VIDEO_TAG_PREVIOUS).forEach((card) => {
        card?.classList.add('hidden')
      })

      // show only the videos that the user has not yet viewed
      forTargets<HTMLDivElement>(root, VIDEO_TAG_CURRENT).forEach((card) => {
        const videoID = forTarget<HTMLDivElement>(card!, 'video-thumbnail')?.dataset.videoId || ''
        const myViews = props.myViews$.getValue()!

        myViews.includes(videoID) || card?.classList.remove('hidden')
      })
    } else {
      // default view

      // show all the videos
      forTargets<HTMLDivElement>(root, VIDEO_TAG_CURRENT).forEach((card) => {
        card?.classList.remove('hidden')
      })

      // hide the videos that won last year
      forTargets<HTMLDivElement>(root, VIDEO_TAG_PREVIOUS).forEach((card) => {
        card?.classList.add('hidden')
      })

      // if "Show Winners" is active, keep it active
      // this will happen when a previous winner video is viewed
      if (document.querySelector('[data-action="show-winners"]')?.classList.contains('active')) {
        // hide all the videos
        forTargets<HTMLDivElement>(root, VIDEO_TAG_CURRENT).forEach((card) => {
          card?.classList.add('hidden')
        })

        // show the videos that won last year
        forTargets<HTMLDivElement>(root, VIDEO_TAG_PREVIOUS).forEach((card) => {
          card?.classList.remove('hidden')
        })
      }
    }
  }

  return {
    watch: [
      { target: props.myViews$, actions: [updateViews, handleFilterChange] },
      { target: currentView$, actions: [handleFilterChange] },
    ],
    start: mount(root, registerVideoPlayerClickListeners, handleFilters),
  }
}
