import { Observable } from "rxjs"
import { ajax, AjaxResponse } from "rxjs/ajax"
import { PersistentRecord } from "./store"

export const createRPClient = (auth$?: PersistentRecord<String | null>) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  if (auth$?.getValue()) {
    headers['Authorization'] = `Bearer ${auth$.getValue()}`
  }

  return <T>(route: string, body: Record<string, any>) => {
    return ajax<{ data: T }>({
      url: process.env.API_URL + 'rpc?route=' + route,
      method: 'POST',
      body,
      headers,
    })
  }
}

export type RPCClientInterface = <T>(route: string, body: Record<string, any>) =>
  Observable<
    AjaxResponse<{
      data: T
    }>
  >
