import { BehaviorSubject, Subject } from 'rxjs'
export type PersistentRecord<T> = BehaviorSubject<Maybe<T>>

export type Store = {
  store: Subject<any>
  get: (key: string) => any
  set: (key: string | Record<any, any>, value?: any) => void
  name: string
  createRecord: <T>(key: string, defaults?: Maybe<T>) => PersistentRecord<T>
  clear: () => void
}

/**
 * Creates a client side store in either local or session storage.
 *
 * @param name
 * @param storage
 */
export function createStore (name = 'dk', storage: Storage = sessionStorage): Store {
  const store = new Subject<Record<string, any>>()
  let prev = {}

  store.subscribe(value => {
    prev = { ...prev, ...value }
    storage.setItem(name, JSON.stringify(prev))
  })

  /**
   * Gets the base store from local storage
   *
   * @returns { Object }
   */
  function getBaseStore (): any {
    const store = (storage.getItem(name) as string) || '{}'
    return JSON.parse(store)
  }

  return {
    store,
    name,

    /**
     * Gets a given value
     *
     * @param key
     * @returns
     */
    get (key) {
      const store = getBaseStore()
      return store[key]
    },

    /**
     * Sets a given value
     *
     * @param key
     * @param value
     */
    set (key, value) {
      const store = getBaseStore()

      if (typeof key === 'object') {
        // eslint-disable-next-line no-return-assign
        const values = Object.keys(key).reduce((acc, k: string) => store[k] = key[k], {})
        storage.setItem(name, JSON.stringify({ ...store, ...values }))
      } else {
        storage.setItem(name, JSON.stringify({ ...store, [key]: value }))
      }
    },

    /**
     * Clears local storage
     */
    clear () {
      storage.clear()
    },

    /**
     * creates an observable record.
     * For more info, refer to https://rxjs.dev/api/index/class/BehaviorSubject
     *
     * @param key
     * @param defaultValue
     */
    createRecord<T> (key: string, defaultValue: Maybe<T> = null) {
      const record = new BehaviorSubject<Maybe<T>>(defaultValue)
      // @ts-ignore
      const existingData = this.get(key)

      if (existingData) record.next(existingData)
      record.subscribe(data => this.set(key, data))

      return record
    },
  }
}
