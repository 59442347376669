type Content = Element[] | Element
type DisplayValue = 'block' | 'inline-block' | 'flex' | 'none'

/**
 * Fetches an element with the specified dk-target value
 *
 * @param root
 * @param target
 * @returns
 */
export const forTarget = <T>(root: RootElement, target: string): Maybe<T> => {
  return root.querySelector(`[dk-target="${target}"]`) as Maybe<T>
}

/**
 * Fetches an element with the specified dk-target value
 *
 * @param root
 * @param target
 * @returns
 */
export const forTargets = <T>(root: RootElement, target: string) => {
  const elements = root.querySelectorAll(`[dk-target="${target}"]`)
  return Array.from(elements).map((element) => element as Maybe<T>)
}
