import { ComponentActions } from "@dk/client/bootstrap"
import { mount } from "@dk/client/mount"
import { RPCClientInterface } from "@dk/client/rpcClient"
import { PersistentRecord } from "@dk/client/store"

type UserVoteProps = {
  RPCClient: RPCClientInterface
  myVotes$: PersistentRecord<string[]>
}

export default async function (root: RootElement, props: UserVoteProps): Promise<ComponentActions> {
  const { RPCClient, myVotes$ } = props

  /**
   * get's the scores for the current user and disables the upvote button if the user has already upvoted the video
   */
  const loadMyVotes = () => {
    RPCClient<{ videos: string[] }>('video.user-upvotes', {}).subscribe(({ response }) => {
      myVotes$.next(response.data?.videos || [])
    })
  }

  const disableUpvoteButton = (myVotes: string[]) => {
    myVotes.forEach((videoID) => {
      const button = root.querySelector(`[dk-target="upvote-button"] dk-data[data-upvote-id="${videoID}"]`)?.closest('a')

      button?.classList.add('disabled')
      button?.classList.remove('zoom')
    })
  }

  return {
    watch: [
      { target: myVotes$, actions: [disableUpvoteButton] },
    ],
    start: mount(root, loadMyVotes)
  }
}
