import * as Sentry from '@sentry/browser'
import { addExtensionMethods, Integrations } from '@sentry/tracing'
import { bootstrap } from '@dk/client/bootstrap'
import { startDKApp } from '@dk/client/app'
import VideoPlayer from './component/VideoPlayer'
import Voting from './component/Voting'
import { createStore } from '@dk/client/store'
import { createRPClient } from '@dk/client/rpcClient'
import Votes from './component/Votes'
import UserVotes from './component/UserVotes'
import Views from './component/Views'

const store = createStore('dk', localStorage)
const auth$ = store.createRecord<String>('auth', crypto.randomUUID())
const votes$ = store.createRecord<Record<string, number>[]>('votes', [])
const myVotes$ = store.createRecord<string[]>('my-votes', [])
const myViews$ = store.createRecord<string[]>('my-views', [])
const RPCClient = createRPClient(auth$)

/**
 * shuffleVideos
 *
 * Shuffles the videos in the DOM
 */
function shuffleVideos() {
  const videos = document.querySelectorAll('[dk-target="video-card"]')
  const videosArray = Array.from(videos)
  const shuffledVideos = videosArray.sort(() => Math.random() - 0.5)

  shuffledVideos.forEach((video: Element) => {
    video.parentElement?.appendChild(video)
  })

  // Sets the video container to full opacity
  document.getElementById('video-container')!.style.opacity = '1'
}

function forbidVotingForPreviousWinners() {
  const previousWinners = document.querySelectorAll('[dk-target="video-card-previous-winner"]')

  previousWinners.forEach((video: Element) => {
    const upvoteButton = video.querySelector('[dk-target="upvote-button"]')
    upvoteButton?.remove()
  })
}

startDKApp(() => {
  shuffleVideos()
  forbidVotingForPreviousWinners()
  bootstrap('video-player', [VideoPlayer, Voting, Votes, UserVotes, Views], { RPCClient, votes$, myVotes$, myViews$ })
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
function fromPromise(arg0: Promise<import("axios").AxiosResponse<any, any>>) {
  throw new Error('Function not implemented.')
}
